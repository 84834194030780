<!-- 共享记录 -->

<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="main-wrap">
			<div class="card-list">
				<listItem2 v-for="(item, index) in tableData" :key="index" :itemData="item" :config="listItemConfig" @click="toDetail(item)">
					<template slot="titleRight">
						<span :style="{ color: formatStatusData(item.orderStatus).color }">{{ formatStatusData(item.orderStatus).label }}</span>
					</template>
				</listItem2>
				<KcEmpty v-if="!tableData.length"></KcEmpty>
			</div>
		</div>
		<detailPopup ref="detailPopup"></detailPopup>
	</van-pull-refresh>
</template>

<script>
import listItem2 from '@components/listItem/listItem2';
import detailPopup from './detailPopup.vue';
import { formatStatusData } from '@/baseConfig/orderStatusOptions/index.js';
export default {
	name: 'approveRecord',
	components: { listItem2, detailPopup },
	inject: ['recordData'],
	data() {
		return {
			isRefresh: false,

			listItemConfig: {
				titleKey: 'deviceName',
        imgKey:'photo',
        layout: [
					{
						label: this.$VUEAPPDEVICECODE(),
						key: 'code'
					},
					{
						label: '卡片号',
						key: 'label'
					},
					{
						label: '设备分类',
						key: 'categoryName'
					},
					{
						label: '品牌',
						key: 'brandName'
					},
					{
						label: '型号',
						key: 'modelName'
					},
					{
						label: '共享时间',
						key: 'orderTimeText'
					}
				]
			},
			tableData: [],

			formatStatusData: formatStatusData
		};
	},
	created() {
		this.getList();
	},
	mounted() {
		this.$bus.on('workManagerRecordSearch', this.getList);
	},
	beforeDestroy() {
		this.$bus.off('workManagerRecordSearch', this.getList);
	},
	methods: {
		onRefresh() {
			this.isRefresh = true;
			this.getList(() => {
				setTimeout(() => {
					this.isRefresh = false;
				}, 300);
			});
		},
		getList(callbak) {
			this.$api.deviceWorkManager
				.orderList({
          isTenantIdChild:1,
					query: this.recordData.baseData.query,
					startTime: this.recordData.baseData.currentDate[0] + ' 00:00:00',
					endTime: this.recordData.baseData.currentDate[1] + ' 23:59:59',
					type: 5
				})
				.then(res => {
					res.data.map(item => {
						item.orderTimeText = this.$filters.formatDateTime(item.orderTime);
					});
					this.tableData = res.data;
					if (typeof callbak == 'function') callbak();
				})
				.catch(() => {
					if (typeof callbak == 'function') callbak();
				});
		},
		toDetail(item) {
			this.$refs.detailPopup.show(item);
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';
</style>
